// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

Rails.start()
ActiveStorage.start()

// import '@fortawesome/fontawesome-free/js/all'
import '../src/application'

$(function() {
  $('.drawer').drawer();
});

$(function() {
  var footerHeight = $('#footer').outerHeight();
  $('body').css('padding-bottom', footerHeight + 64);
});

$(function() {
  $('a[href^="#"]').on('click', function() {
    var href = $(this).attr('href');
    var target = href == '#' ? 'html' : href;
    var position = $(target).offset().top;

    $('html, body').animate({ scrollTop: position }, 500, 'swing');
    if ($(window).scrollTop() < position) {
      $('.scroll-btn').removeClass('hidden');
    } else {
      $('.scroll-btn').addClass('hidden');
    }
  });
});
